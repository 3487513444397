import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Modal.css";
import { RxCross2 } from "react-icons/rx";
const Modal = ({ isOpen, onClose, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent closing the modal when clicking inside it
  };

  const handleBackdropClick = () => {
    handleClose();
  };

  return isModalOpen
    ? ReactDOM.createPortal(
        <div className="modal-backdrop" onClick={handleBackdropClick}>
          <div className="modal-content" onClick={handleModalClick}>
            <RxCross2
              onClick={handleBackdropClick}
              style={{
                position: "absolute",
                right: 20,
                cursor: "pointer",
                zIndex: "993",
              }}
              size={30}
              color="#fff"
            />
            {children}
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;

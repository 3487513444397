import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./Header.css"; // Import a CSS file for styling (optional)

const Header = () => {
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState(null);

  const handleNavLinkClick = (event, link) => {
    event.preventDefault();
    setActiveLink(link);
    navigate(`/${link}`);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent ">
      <a className="navbar-brand" href="/">
        <img
          src="https://storage.googleapis.com/anima-react/logo.png"
          className="logo"
          alt="Logo"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img
          src="https://storage.googleapis.com/anima-react/toggle-icon.png"
          alt="Toggle"
          className="toggle-icon" // Add a class for styling the custom icon
        />
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink
              className="nav-link h-to-play"
              to="/howToPlay"
              onClick={(event) => handleNavLinkClick(event, "howToPlay")}
              activeClassName="active"
            >
              How to Play
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link b-affiliate"
              to="/becomeAffilliate"
              onClick={(event) => handleNavLinkClick(event, "becomeAffilliate")}
              activeClassName="active"
            >
              Become an Affiliate
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav nav-social-icons ml-auto mr-5">
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://twitter.com/cryptoclashgg?s=21"
              target="_blank"
            >
              <img
                src="https://storage.googleapis.com/anima-react/x.png"
                alt="x"
              />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <img
                src="https://storage.googleapis.com/anima-react/discord.png"
                alt="Twitter"
              />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;

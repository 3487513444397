import React from "react";
import "./SecondComponent.css";
import CryptoTable from "./CryptoTable";
const SecondComponent = () => {
  return (
    <div className="table-parnet">
      <div className="row">
        <div className="affillite-crypto-table">
          <CryptoTable
            title="AFFILIATE LEADERBOARD "
            subtitle="Make money when your friends make money!"
          />
        </div>
      </div>
    </div>
  );
};

export default SecondComponent;

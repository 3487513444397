import React, { useState, useEffect } from "react";
import "./SectionEight.css";
import { BsChevronDown } from "react-icons/bs";

function SectionEight() {
  const [faqData, setFaqData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.upvsdown.com/v1/config/faq?lang=en")
      .then((response) => response.json())
      .then((data) => {
        setFaqData(data.about);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="sec-4-home">
      <div className="sec-8-table">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 className="sec-8-head">FAQ</h1>
          <p className="sec-4-head-right">
            About the game <BsChevronDown />
          </p>
        </div>
        <div className="sec-8-table-inner">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="accordion-faq" id="faq">
                {faqData.map((faqItem, index) => (
                  <div className="card" key={index}>
                    <div
                      className="card-header"
                      id={`faqhead${index + 1}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn2 btn-header-link d-flex justify-content-start align-items-center"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#faq${index + 1}`}
                        aria-expanded="true"
                        aria-controls={`faq${index + 1}`}
                      >
                        <span
                          className="title"
                          style={{ textAlign: "left", paddingLeft: "0" }}
                        >
                          {faqItem.question}
                        </span>
                      </button>

                      <BsChevronDown
                        className="arrow"
                        data-toggle="collapse"
                        data-target={`#faq${index + 1}`}
                        aria-expanded="true"
                        aria-controls={`faq${index + 1}`}
                      />
                    </div>
                    <div
                      id={`faq${index + 1}`}
                      className={`collapse ${index === 0 ? "" : ""}`}
                      aria-labelledby={`faqhead${index + 1}`}
                      data-parent="#faq"
                    >
                      <div
                        className="card-body"
                        dangerouslySetInnerHTML={{ __html: faqItem.answer }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            textAlign: "left",
            width: "100%",
            height: "50px",
            display: "flex",
            color: "#fff !important",
            justifyContent: "flex-start",
          }}
        >
          <p style={{ marginTop: "55px", color: "#ffffff," }}>
            &#9632; 1000011.1000011
          </p>
        </div>
      </div>
    </div>
  );
}

export default SectionEight;

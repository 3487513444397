import React from "react";
import styles from "./InvestmentBar.module.css";

const InvestmentBar = () => {
  return (
    <div className={styles["investment-bar"]}>
      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinte.png"
                className={styles["investment-icon"]}
              />
              <div className="p-1">
                <span className={styles["investment-number"]}>5 </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinte.png"
                className={styles["investment-icon"]}
              />
              <div className="p-1">
                <span className={styles["investment-number"]}>10 </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinte.png"
                className={styles["investment-icon"]}
              />
              <div className="p-1">
                <span className={styles["investment-number"]}>15 </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button style={{ background: "#F4D56F" }}>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinity-1.png"
                className={styles["investment-icon"]}
                style={{ color: "blue" }} // Change color for the image
              />
              <div className="p-1">
                <span
                  className={styles["highlighted-investment-number"]}
                  style={{ color: "green !imspanortant" }} // Change color for the paragraph
                >
                  20
                </span>
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinte.png"
                className={styles["investment-icon"]}
              />
              <div className="p-1">
                <span className={styles["investment-number"]}>25 </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={styles["col-item"]}>
        <div className={styles["investment-item"]}>
          <button>
            <div className={styles["button-content-investent"]}>
              <img
                src="https://storage.googleapis.com/anima-react/infinte.png"
                className={styles["investment-icon"]}
              />
              <div className="p-1">
                <span className={styles["investment-number"]}>30 </span>
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className="col text-center">
        <button className={styles["big-button"]}>Investment Bar</button>
      </div>
    </div>
  );
};

export default InvestmentBar;

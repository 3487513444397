import React from "react";
import "./FourthComponent.css";
import SectionNine from "../../Home/sections/SectionNine";
const FourthComponent = () => {
  return (
    <div className="become-fourth-parnet">
          <SectionNine
            header="YOUR EARNINGS ARE IN POLYGON MATIC"
            firstP="The top 10 crypto token, #1 web3 blockchain, trusted by Instagram and Meta"
          />
    </div>
  );
};

export default FourthComponent;

import React from "react";
import "./ThirdComponent.css";
const ThirdComponent = () => {
  return (
    <div className="third-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              src="https://storage.googleapis.com/anima-react/trade.png"
              alt="trading"
              className="trade"
            />
          </div>
          <div className="col-md-6">
            <span>Core information</span>
            <ul>LIVE BITCOIN RATE</ul>
            <p>
              The real-time Bitcoin rate, acting as the adjudicator for victory
              proclamations, dictates the results on Crypto Clash. If the live
              rate bar resides above or below the start rate, indicating an
              upward movement, it signifies triumph for the green team (UP) ;
              conversely, a downward movement declares victory for the red team
              (DOWN).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdComponent;

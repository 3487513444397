import React from "react";
import { BsChevronDown } from "react-icons/bs";
import "./Feedback.css";

const Feedback = () => {
  return (
    <div id="main">
      <div className="matic-crypto-coin-width">
        <div className="row">
          <div className="col-md-4 col-sm-12 text">
            <span>The official token of the game</span>
            <h2>POLYGON MATIC</h2>
          </div>
          <div className="col-md-6 col-sm-12 text">
            <div>
              <p>
                The top 10 crypto token, #1 web3 blockchain, trusted by
                Instagram and Meta
              </p>
            </div>
          </div>
          <div className="col-md-2 col-sm-12 img-with-drop">
            <div style={{ padding: "0 8px" }}>
              <div className="back">
                <img
                  src="https://storage.googleapis.com/anima-react/infinte.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="accordion matic-coin-style">
          <div className="card">
            <div
              className="card-header"
              id="faqhead1"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-header-link d-flex justify-content-between align-items-center"
                type="button"
                data-toggle="collapse"
                data-target="#faq1"
                aria-expanded="true"
                aria-controls="faq1"
              >
                <span className="title">How to get matic tokens?</span>
              </button>
              <BsChevronDown
                className="arrow"
                data-target="#faq1"
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls="faq1"
              />
            </div>

            <div
              id="faq1"
              className="collapse"
              aria-labelledby="faqhead1"
              data-parent="#faq"
            >
              <div className="card-body">
                Buy tokens easily with credit/debit or bank transfer instantly
                ans easily via your cryptowallet. Or exchange other crypto to
                matic easily and safetly with our best swap service.
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="faqhead2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-header-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq2"
                aria-expanded="true"
                aria-controls="faq2"
              >
                <span className="title">
                  How to sell my winning matic tokens?
                </span>
              </button>
              <BsChevronDown
                className="arrow"
                data-toggle="collapse"
                data-target="#faq2"
                aria-expanded="true"
                aria-controls="faq2"
              />
            </div>

            <div
              id="faq2"
              className="collapse"
              aria-labelledby="faqhead2"
              data-parent="#faq"
            >
              <div className="card-body">
                Buy tokens easily with credit/debit or bank transfer instantly
                ans easily via your cryptowallet. Or exchange other crypto to
                matic easily and safetly with our best swap service.
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="faqhead3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-header-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq3"
                aria-expanded="true"
                aria-controls="faq3"
              >
                <span className="title">
                  How to send my winning matic tokens?
                </span>
              </button>
              <BsChevronDown
                className="arrow"
                data-toggle="collapse"
                data-target="#faq3"
                aria-expanded="true"
                aria-controls="faq3"
              />
            </div>

            <div
              id="faq3"
              className="collapse"
              aria-labelledby="faqhead3"
              data-parent="#faq"
            >
              <div className="card-body">
                Buy tokens easily with credit/debit or bank transfer instantly
                ans easily via your cryptowallet. Or exchange other crypto to
                matic easily and safetly with our best swap service.
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="faqhead4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-header-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq4"
                aria-expanded="true"
                aria-controls="faq4"
              >
                <span className="title">
                  Can I access the game via my mobile?
                </span>
              </button>
              <BsChevronDown
                className="arrow"
                data-toggle="collapse"
                data-target="#faq4"
                aria-expanded="true"
                aria-controls="faq4"
              />
            </div>

            <div
              id="faq4"
              className="collapse"
              aria-labelledby="faqhead4"
              data-parent="#faq"
            >
              <div className="card-body">
                Buy tokens easily with credit/debit or bank transfer instantly
                ans easily via your cryptowallet. Or exchange other crypto to
                matic easily and safetly with our best swap service.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

import React from "react";
import "./SevenComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const SevenComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };

  return (
    <div className="seven-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              src="https://storage.googleapis.com/anima-react/sevenimage.png"
              className="seven-image"
            />
          </div>
          <div className="col-md-6">
            <ul>PROFITS</ul>
            <h3>
              The winners get their profits into their digitial wallet
              immediately.
            </h3>
            <p style={{ textAlign: "center" }}>
              The Profits are Divided Equally Subject To The Investment Ratio of
              The Investors In The Pools Minus The Earnings Fees Commission
            </p>
            <div className="row">
              <div className="col-sm-12 col-md-6 align-center">
                <BaseButton title="Play " onClick={connectToPlay} />
              </div>
              <div className="col-sm-12 col-md-6 align-center">
                <button
                  className="btn"
                  onClick={connectToPlay}
                  style={{ cursor: "pointer" }}
                >
                  And earn crypto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SevenComponent;

import React from "react";
import Header from "../../components/header/Header";
import "./HowToPlay.css";
import FirstComponent from "./firstsection/FirstComponent";
import SecondComponent from "./secondsection/SecondComponent";
import ThirdComponent from "./thirdsection/ThirdComponent";
import FourthComponent from "./fourthsection/FourthComponent";
import FifthComponent from "./fifthsection/FifthComponent";
import SixComponent from "./sixsection/SixComponent";
import SevenComponent from "./sevensection/SevenComponent";
import EightComponent from "./eightsection/EightComponent";
import NineComponent from "./nineSection/NineComponent";
import TenComponent from "./tensection/Feedback";
function HowToPlay() {
  return (
    <div>
      <div className="how-to-bg">
        <Header />
        <FirstComponent />
      </div>
      <SecondComponent />
      <ThirdComponent />
      <FourthComponent />
      <FifthComponent />
      <SixComponent />
      <SevenComponent />
      <EightComponent />
      <NineComponent />
      <TenComponent />
    </div>
  );
}

export default HowToPlay;

import React from "react";
import "./NineComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const NineComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };

  return (
    <div className="seven-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6 ">
            <img
              src="https://storage.googleapis.com/anima-react/first-image-beside.png"
              className="first-image-beside"
              width="500"
            />
          </div>
          <div className="col-md-6">
            <ul> MONTHLY JACKPOT</ul>

            <p className="create-aff-p">
              If you have lucky friends you also win a share of the big monthly
              jackpot.
            </p>
            <div className="row">
              <div className="col nine-btns">
                <BaseButton title="Play " onClick={connectToPlay} />
              </div>
              <div className="col  nine-btns">
                <button className="btn" onClick={connectToPlay}>
                  Let's GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NineComponent;

import React from "react";
import "./FourthComponent.css";
const FourthComponent = () => {
  return (
    <div className="fourth-parnet">
      <div className="container">
        <div className="row">
          <p className="fourth-title"> Choose your side wisely</p>
        </div>
        <div className="row">
          <h3>
            JOIN
            <span className="up">UP</span>
            <img
              src="https://storage.googleapis.com/anima-react/Up%20icon.png"
              alt="up trading"
              height="70"
            />
            POOL IF YOU THINK BITCOIN IS GOING UP
          </h3>
        </div>
        <div className="row">
          <h3>
            JOIN
            <span className="down">Down</span>
            <img
              src="https://storage.googleapis.com/anima-react/Down%20icon.png"
              alt="down trading"
              height="70"
            />
            POOL IF YOU THINK BITCOIN IS GOING DOWN
          </h3>
        </div>
      </div>
    </div>
  );
};

export default FourthComponent;

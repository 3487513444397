import React from "react";
import "./SectionSeven.css";
function SectionSeven() {
  return (
    <div className="sec-2-home">
      <p className="hero-txt-top">
        PAID SO FAR AND<span className="bottom-hero-txt">WIN</span> RATIO
      </p>
    </div>
  );
}

export default SectionSeven;

import React from "react";
import "./ThirdComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const ThirdComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/share${queryparam}`, "_self");
  };
  return (
    <div className="third-parnet">
      <div className="container">
        <div className="row third-plans">
          <h2 className="third-title">
            JOIN THE MULTI LEVEL AFFILIATE PARTNER PROGRAM
          </h2>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 col-sm-12 first">
            <h2>10%</h2>
            <p>Tier 2</p>
          </div>
          <div className="col-md-4 col-sm-12 second">
            <img
              src="https://storage.googleapis.com/anima-react/thirdrectangle.png"
              style={{ borderRadius: "4px" }}
            />
            <h2>20%</h2>
            <p>Tier 1</p>
            <div className="base-btn">
              <BaseButton title="Let's GET STARTED" onClick={connectToPlay} />
            </div>
            <button className="btn custom-btn" onClick={connectToPlay}>
              Easy and Free
            </button>
          </div>
          <div className="col-md-4 col-sm-12 third">
            <h2>5%</h2>
            <p>Tier 3</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdComponent;

import React from "react";
import "./EightComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const EightComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };
  return (
    <div className="eight-parnet">
      <div className="container">
        <div className="row">
          <div class="col-md-6 order-md-1 order-2">
            <ul>WEEKLY JACKPOT </ul>
            <h2>
              10% of the platform's income will be raffled among all the
              participants in the game.
            </h2>
            <p>
              Each transaction you take earns you a lottery ticket, the more you
              play, the more lottery tickets you will have and your chances of
              winning will increase, good luck!
            </p>
            <div className="row">
              <BaseButton title="Play " onClick={connectToPlay} />
              <button
                className="eight-btn"
                onClick={connectToPlay}
                style={{ cursor: "pointer" }}
              >
                And earn crypto
              </button>
            </div>
          </div>
          <div class="col-md-6 order-md-1 order-1">
            <img
              src="https://storage.googleapis.com/anima-react/eightsideimage.webp"
              className="side-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EightComponent;

import React, { useState, useEffect } from "react";
import "./SectionFour.css";

function SectionFour({
  title = "TOP WINNERS",
  subtitle = null,
  rightItem = null,
}) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.upvsdown.com/v1/stats/leaderboard?ref=5d41c")
      .then((response) => response.json())
      .then((data) => {
        setTableData(
          data.map((item) => ({
            id: item.wallet.slice(0, 6) + "..." + item.wallet.slice(-4),
            trades: parseFloat(item.tradesCount).toFixed(3),
            wins: parseFloat(item.winningsCount).toFixed(3),
            winRatio:
              ((item.winningsCount / item.tradesCount) * 100).toFixed(1) + "%",
            winAmount: item.profit.toFixed(2),
            img: item.avatarUrl,
            address: item.wallet, // Adding address to data
          }))
        );
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);
  const mobile = window.innerWidth <= 576;
  return (
    <div className="sec-4-home">
      <div className="sec-4-table">
        <div className="main-title">
          <h1 className="sec-4-head">{title}</h1>
          {subtitle && <p>{subtitle}</p>}
          {rightItem && <div>{rightItem}</div>}
        </div>
        <div className="sec-4-table-inner">
          {loading ? (
            <p>Loading...</p>
          ) : (
            tableData.map((data, i) => {
              return (
                <a
                  href={`https://polygonscan.com/address/${data.address}`}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <div className="table-data">
                    <p>{i + 1}</p>
                    <div style={{ display: "flex", width: "90%" }}>
                      <img
                        src={data.img}
                        alt={data.id}
                        height="43"
                        style={{
                          padding: "8px",
                          width: mobile ? "70%" : "20%",
                        }}
                      />
                      <p>{data.id}</p>
                    </div>
                    <p>{data.trades}</p>
                    <p>{data.wins}</p>
                    <p>{data.winRatio}</p>
                    <p>{data.winAmount}</p>
                  </div>
                </a>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionFour;

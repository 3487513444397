import React from "react";
import "./SixComponent.css";
const SixComponent = () => {
  return (
    <div className="six-parnet">
      <div className="container">
        <div className="row">
          <p className="six-title"> Choose your side wisely</p>
        </div>
        <div className="row">
          <h3>
            JOIN
            <span className="up">UP</span>
            <img
              src="https://storage.googleapis.com/anima-react/Up%20icon.png"
              alt="up trading"
              height="70"
            />
            POOL POTENTIAL PROFIT <label className="up-percent">400%</label>
          </h3>
        </div>
        <div className="row">
          <h3>
            JOIN
            <span className="down">Down</span>
            <img
              src="https://storage.googleapis.com/anima-react/Down%20icon.png"
              alt="down trading"
              height="70"
            />
            POOL POTENTIAL PROFIT <label className="down-percent">150%</label>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SixComponent;

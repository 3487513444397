import React from "react";
import "./Wallet.css";

const Wallet = () => {
  return (
    <div className="wallet-container">
      <div className="wallet-button">
        <button className="wallet-button-content-1">
          <img
            src="https://storage.googleapis.com/anima-react/coinbase.png"
            alt="Wallet Icon"
            width="300"
            className="coinbase-icon"
          />
        </button>
      </div>
      <div className="wallet-button">
        <button className="wallet-button-content-2">
          <img
            src="https://storage.googleapis.com/anima-react/metamask.png"
            alt="Add Funds Icon"
            className="metamask-icon"
          />
        </button>
      </div>
      <div className="wallet-button">
        <button className="wallet-button-content-3">
          <img
            src="https://storage.googleapis.com/anima-react/walletconnect.png"
            alt="Transfer Icon"
            className="walletconnect-icon"
          />
        </button>
      </div>
    </div>
  );
};

export default Wallet;

import React, { useState } from "react";
import "./SectionThree.css";
import BaseButton from "../../../components/Button/BaseButton";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { generateUUID } from "../../../utils";

function SectionThree() {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };

  const mobile = window.innerWidth <= 576;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const mobileFeature = [
    {
      text: "Winning withdraw",
    },
    {
      text: "Deposit",
    },
    {
      text: "Play against the house",
    },
    {
      text: "Chance to win",
    },
    {
      text: "Peer to peer",
    },
    {
      text: "Transparency",
    },
  ];
  const pricings = [
    {
      title: "COMPARISION TABLE",

      features: [
        {
          text: "Winning withdraw",
        },
        {
          text: "Deposit",
        },
        {
          text: "Play against the house",
        },
        {
          text: "Chance to win",
        },
        {
          text: "Peer to peer",
        },
        {
          text: "Transparency",
        },
      ],
    },
    {
      title: "Gaming",
      discount: "30%",
      win: "WIN RATIO",
      features: [
        {
          check: "cross",
        },
        {
          check: "check",
        },
        {
          check: "check",
        },
        {
          check: "cross",
        },
        {
          check: "cross",
        },
        {
          check: "cross",
        },
      ],
    },
    {
      title: "CryptoClash",
      discount: "55%+",
      win: "WIN RATIO",
      features: [
        {
          text: "Auto Payments",
          check: "check",
        },
        {
          text: "Connect Wallet",
          check: "cross",
        },
        {
          text: "No",
          check: "cross",
        },
        {
          text: "Hell yes!",
          check: "check",
        },
        {
          text: "Yes",
          check: "check",
        },
        {
          text: "Yes, 100%",
          check: "check",
        },
      ],
    },

    {
      title: "Finance",
      discount: "25%",
      win: "WIN RATIO",
      features: [
        {
          check: "cross",
        },
        {
          check: "check",
        },
        {
          check: "check",
        },
        {
          check: "cross",
        },
        {
          check: "cross",
        },
        {
          check: "cross",
        },
      ],
    },
  ];
  return (
    <div className="sec-3-home">
      {/* <div className="sec-3-chart"></div> */}
      {mobile ? (
        <div>
          <p className="sec-3-mobile-first-p normal-font">COMPARISION TABLE</p>
          <div className="sec-3-mobile-head-container">
            {["GAMING", "CryptoCLash", "Finance"].map((category, i) => {
              return (
                <div
                  onClick={() => setSelectedIndex(i)}
                  style={{ background: i == selectedIndex ? "#ffd23e" : "" }}
                  className="sec-3-mobile-head-child"
                >
                  <p style={{ color: i == selectedIndex ? "#000" : "#fff" }}>
                    {category}
                  </p>
                </div>
              );
            })}
          </div>

          <div
            className="sec-3-chart-dummy"
            style={{
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <div
              style={{ border: "1px solid #ffd23e" }}
              className="sec-3-price-chart-child"
            >
              <div className="sec-3-price-chart-head">
                <p
                  className="normal-font"
                  style={{
                    color: selectedIndex === 1 ? "#ffd23e" : "inherit",
                    fontFamily: "Space Grotesk",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {pricings[selectedIndex + 1].title}
                </p>
                {pricings[selectedIndex + 1].discount && (
                  <p
                    className="normal-font"
                    style={{
                      color: selectedIndex === 1 ? "#ffd23e" : "inherit",
                      fontFamily: "Space Grotesk",
                      fontSize: "43px",
                      fontWeight: 700,
                      lineHeight: "25px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {pricings[selectedIndex + 1].discount}
                  </p>
                )}
                {pricings[selectedIndex + 1].win && (
                  <p
                    className="normal-font"
                    style={{
                      color: selectedIndex === 1 ? "#ffd23e" : "inherit",
                      fontFamily: "Space Grotesk",
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "25px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {pricings[selectedIndex + 1].win}
                  </p>
                )}
              </div>

              <div className="sec-3-price-chart-body">
                {pricings[selectedIndex + 1].features.map((feature, i) => {
                  return (
                    <div className="sec-3-price-chart-body-child">
                      {feature.check === "check" && (
                        <div className="pricing-icon">
                          <MdCheck size={16} color="#ffd23e" />
                        </div>
                      )}
                      {feature.check === "cross" && (
                        <div className="pricing-icon-cross">
                          <RxCross2 size={16} color="#fff" />
                        </div>
                      )}
                      <p>{mobileFeature[i].text} </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="sec-3-btn">
            <BaseButton title="PLAY" onClick={connectToPlay} />
            <p
              style={{
                color: "#FFD23E",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              onClick={connectToPlay}
            >
              And earn crypto
            </p>
          </div>
        </div>
      ) : (
        <div className="sec-3-price-chart">
          <img className="table-line" src="assets/img/pricing-line.png" />
          {pricings.map((pricing, i) => {
            return (
              <div
                className="sec-3-chart-dummy"
                style={{
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ border: i == 2 ? "1px solid #ffd23e" : "" }}
                  className="sec-3-price-chart-child"
                >
                  {i == 2 && (
                    <div className="table-best-opt">
                      <p>BEST OPTION</p>
                    </div>
                  )}
                  <div className="sec-3-price-chart-head">
                    <p
                      className="normal-font"
                      style={{
                        textWrap: i == 0 ? "pretty" : "nowrap",
                        color: i == 0 || i == 2 ? "#ffd23e" : "#fff",
                        fontSize: i == 0 || i == 2 ? "22px" : "",
                        fontWeight: i == 0 || i == 2 ? 700 : "",
                        lineHeight: "25px",
                        textAlign: "center",
                      }}
                    >
                      {pricing.title}
                    </p>
                    {pricing.discount && (
                      <p
                        className="normal-font"
                        style={{
                          color: i == 0 || i == 2 ? "#ffd23e" : "#fff",
                          fontSize: i == 0 || i == 2 ? "40px" : "",
                          fontWeight: i == 0 || i == 2 ? 700 : "",
                          lineHeight: "25px",
                          textAlign: "center",
                        }}
                      >
                        {pricing.discount}
                      </p>
                    )}
                    {pricing.win && (
                      <p
                        className="normal-font"
                        style={{
                          color: i == 0 || i == 2 ? "#ffd23e" : "#fff",
                          fontSize: i == 0 || i == 2 ? "22px" : "",
                          fontWeight: i == 0 || i == 2 ? 700 : "",
                          lineHeight: "25px",
                          textAlign: "center",
                        }}
                      >
                        {pricing.win}
                      </p>
                    )}
                  </div>

                  <div className="sec-3-price-chart-body">
                    {pricing.features.map((feature) => {
                      return (
                        <div className="sec-3-price-chart-body-child">
                          {feature.check === "check" && (
                            <div className="pricing-icon">
                              <MdCheck size={16} color="#ffd23e" />
                            </div>
                          )}
                          {feature.check === "cross" && (
                            <div className="pricing-icon-cross">
                              <RxCross2 size={16} color="#fff" />
                            </div>
                          )}
                          {feature.text && <p>{feature.text} </p>}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {i == 2 && (
                  <div className="sec-3-btn">
                    <BaseButton title="PLAY" onClick={connectToPlay} />
                    <p
                      style={{
                        color: "#FFD23E",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      onClick={connectToPlay}
                    >
                      And earn crypto
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SectionThree;

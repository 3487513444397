import React, { useState, useEffect } from "react";
import "./CryptoTable.css";

function CryptoTable({ title = "TOP WINNERS", subtitle }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const mobile = window.innerWidth <= 576;

  useEffect(() => {
    fetch("https://api.upvsdown.com/v1/stats/affiliates/leaderboard")
      .then((response) => response.json())
      .then((data) => {
        const mappedData = data.data.map((item, index) => ({
          id: `${item.wallet.substring(0, 4)}***${item.wallet.slice(-2)}`,
          trades: parseFloat(item.perTiers["1"]).toFixed(3),
          wins: parseFloat(item.perTiers["2"]).toFixed(3),
          winRatio:
            (
              (parseFloat(item.perTiers["2"]) /
                parseFloat(item.perTiers["1"])) *
              100
            ).toFixed(2) + "%",
          winAmount: parseFloat(item.totalAmount).toFixed(3),
          img: item.avatarUrl,
          rank: index + 1,
          address: item.wallet, // Add the address to the mapped data
        }));
        setTableData(mappedData);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  return (
    <div className="sec-00-home">
      <div className="sec-4-table">
        <div className="main-title">
          <h1 className="sec-4-head">{title}</h1>
          <p className="sec-4-subtitle">{subtitle}</p>
        </div>
        <div className="sec-4-table-inner">
          {loading ? (
            <p>Loading...</p> // Show loading message while data is being fetched
          ) : (
            tableData.map((data, i) => (
              <a
                href={`https://polygonscan.com/address/${data.address}`}
                key={i}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="table-data2">
                  <p>{data.rank}</p>
                  <div style={{ display: "flex", width: "90%" }}>
                    <img
                      src={data.img}
                      alt={data.id}
                      height="43"
                      style={{
                        padding: "8px",
                        width: mobile ? "70%" : "20%",
                      }}
                    />
                    <p>{data.id}</p>
                  </div>
                  <p>{data.trades}</p>
                  <p>{data.wins}</p>
                  <p>{data.winRatio}</p>
                  <p>{data.winAmount}</p>
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default CryptoTable;

import React from "react";
import "./FifthComponent.css";
const FifthComponent = () => {
  return (
    <div className="fifth-parnet">
      <div className="container">
        <div className="row how-it-works">
          <div className="col-md-6 col-sm-12">
            <span>Step by step</span>
            <ul>How it Works</ul>
            <p className="create-aff-p">
              Create affiliate links, unlimited amount. Share your link through
              your social network. Get revshare on your friends paid commision.
            </p>
          </div>
          <div className="col-md-5 col-sm-12">
            <div
              className="icons"
              style={{
                backdropFilter: "blur(33px)",
                padding: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="icon-row">
                <div>
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/x-logo-twitter-elon-musk_dezeen_2364_col_0-1.png"
                    alt=""
                    className="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/telegram-512.png"
                    alt=""
                    className="icon"
                  />
                </div>
                <div>
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/instagram-new-logo.png"
                    alt=""
                    className="icon"
                  />
                </div>
              </div>
              <div className="icon-row">
                <div>
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/Facebook_logo_(square).png"
                    alt=""
                    className="icon"
                  />
                </div>
                <div>
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/tiktok-logo-tikok-icon-transparent-tikok-app-logo-free-png.png"
                    alt=""
                    className="icon"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/anima-react/icons/Rectangle%2082.png"
                    alt=""
                    className="icon"
                  />
                  <div
                    style={{
                      position: "absolute",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthComponent;

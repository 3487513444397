import React from "react";
import "./SectionSix.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
function SectionSix() {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };
  return (
    <div className="sec-6-home">
      <p className="sec-6-txt-top">
        MAKE PASSIVE<span className="bottom-sec-6-txt">DAILY INCOME</span>
      </p>
      <p className="sec-6-txt-para">
        Get up to 35% commision on your friends earning fees. Join our multi
        level referral program!
      </p>
      <p className="sec-6-txt-para2">
        Bring your friends to play and get automated daily passive income
        directly to your wallet , from their winning fees they pay.
      </p>
      <div className="sec-6-bottom-btm">
        <BaseButton title="LEARN MORE" onClick={connectToPlay} color="#fff" />
      </div>
      <div className="sec-6-bottom-img">
        <img src="https://storage.googleapis.com/anima-react/sec-6-bottom-img.png" />
      </div>
    </div>
  );
}

export default SectionSix;

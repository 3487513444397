import React from "react";
import "./SecondComponent.css";
import Wallet from "./Wallet";
const SecondComponent = () => {
  return (
    <div className="second-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span>Getting started</span>
            <ul>CONNECT YOUR WALLET, OR CREATE SOCIAL WALLET!</ul>
            <p>
              To get started on your crypto-earning journey, you'll have to
              connect your wallet to the website. Crypto Clash supports various
              wallet providers. Select your preferred wallet from the list.
              Common options include MetaMask, Trust Wallet, or Coinbase Wallet.
            </p>
          </div>
          <div className="col-md-6 wallet-div">
            <span>Recommended</span>

            <Wallet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondComponent;

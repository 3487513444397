import React, { useState } from "react";
import "./Hero.css";
import BaseButton from "../../../components/Button/BaseButton";
import Modal from "../../../components/Modal/Modal";
import { generateUUID } from "../../../utils";
function HomeHero() {
  const [isOpen, setIsOpen] = useState(false);
  const queryparam = window.location.search;
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };

  return (
    <div className="herosechome">
      <img
        className="herobhimg"
        alt="hero-bh-img"
        src="https://storage.googleapis.com/anima-react/home.png"
      />
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <div>
          <video
            controls
            autoPlay
            width={window.innerWidth - 300}
            height={window.innerHeight - 300}
            id="hero-video"
          >
            <source
              src="https://storage.googleapis.com/anima-react/update-mobile-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>

      <p className="hero-txt-top">Up or down predict & Win</p>
      <img
        alt="hero-bh-img"
        className="crypto-clash-img"
        src="https://storage.googleapis.com/anima-react/home/cryptoClashMax.png"
      />
      <p className="hero-txt-botm">choose your side wisely</p>
      <div className="play-earn-btn">
        <BaseButton title={"PLAY AND EARN"} onClick={connectToPlay} />
      </div>

      <div className="bottom-hero-sec">
        <div onClick={toggleModal} className="left-icon-hero">
          <img
            width="10%"
            src="https://storage.googleapis.com/anima-react/play.png"
          />
          <p className="bottom-hero-txt-home">Watch trailer</p>
        </div>
        <img
          className="right-icon-hero"
          width="15%"
          src="https://storage.googleapis.com/anima-react/certikLogo.png"
        />
      </div>
    </div>
  );
}

export default HomeHero;

import React from "react";
import "./SectionNine.css";
import BaseButton from "../../../components/Button/BaseButton";
import { useNavigate } from "react-router-dom";
import { generateUUID } from "../../../utils";

function SectionNine({
  header = "POLYGON BLOCKCHAIN NETWORK",
  firstP = " The game is running on #1 Web3 polygon blockchain network , to play thegame you need to have polygon Matic coins.",
  secondP = " Our game is #1 Web3 game in the polygon network and Top 10 in sendingMatic coins.",
  buttonT = "How to play",
}) {
  const navigate = useNavigate();
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };
  const handleNavLinkClick = (event, link) => {
    event.preventDefault();
    navigate(`/${link}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  return (
    <div className="sec-9-home">
      <img src="https://storage.googleapis.com/anima-react/blockChain-logo.png" />
      <p className="sec-9-txt-top">{header}</p>
      <p className="sec-9-txt-para">{firstP}</p>
      <p className="sec-9-txt-para2">{secondP}</p>
      <div className="sec-9-bottom-btm">
        <BaseButton title="PLAY" onClick={connectToPlay} />
        <p
          className="sec-9-txt-botm"
          onClick={(event) => handleNavLinkClick(event, "howToPlay")}
        >
          {buttonT}
        </p>
      </div>
    </div>
  );
}

export default SectionNine;

import React from "react";
import Header from "../../components/header/Header";
import "./BecomeAffiliate.css";
import FirstComponent from "./firstsection/FirstComponent";
import SecondComponent from "./secondsection/SecondComponent";
import ThirdComponent from "./thirdsection/ThirdComponent";
import FifthComponent from "./fifthsection/FifthComponent";
import SevenComponent from "./sevensection/SevenComponent";
import EightComponent from "./eightsection/EightComponent";
import NineComponent from "./nineSection/NineComponent";
import TenComponent from "./tensection/Feedback";
import SectionEight from "../Home/sections/SectionEight";
import FourthComponent from "./fourthsection/FourthComponent";
import FaqEightOne from "./faq-eight-one/FaqEightOne";
function BecomeAffiliate() {
  return (
    <div>
      <div className="become-aff-bg">
        <Header />
        <FirstComponent />
      </div>
      <SecondComponent />
      <ThirdComponent />
      <EightComponent />
      <FifthComponent />
      <SevenComponent />
      <NineComponent />
      <FourthComponent />
      <FaqEightOne />
      {/* <SectionEight /> */}
      <TenComponent />
    </div>
  );
}

export default BecomeAffiliate;

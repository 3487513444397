import React, { useState, useEffect } from "react";
import "./SectionTwo.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";

function SectionTwo() {
  const queryparam = window.location.search;
  const [summaryData, setSummaryData] = useState(null);
  const formatAmountInMillionsThousandsBillions = (number) => {
    if (number >= 1000000000) {
      // If the number is billion or more
      const inBillions = number / 1000000000;
      return (
        inBillions.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
        " Billion"
      );
    } else if (number >= 1000000) {
      // If the number is million or more
      const inMillions = number / 1000000;
      return (
        inMillions.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
        " Million"
      );
    } else if (number >= 1000) {
      // If the number is thousand or more
      const inThousands = number / 1000;
      return (
        inThousands.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
        " Thousand"
      );
    } else {
      return number.toLocaleString(); // If the number is less than a thousand, return it as is
    }
  };

  useEffect(() => {
    fetch("https://api.upvsdown.com/v1/stats/summary")
      .then((response) => response.json())
      .then((data) => {
        setSummaryData(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/trade${queryparam}`, "_self");
  };

  return (
    <div className="sec-2-home">
      <p className="hero-txt-top-paid">
        PAID SO FAR AND<span className="bottom-hero-txt">WIN</span> RATIO
      </p>
      <div className="boxes-wraper">
        {summaryData && (
          <>
            <div className="sec-2-box-wrap">
              <p className="title">Last 24H</p>
              <p className="subtitle">WIN RATIO</p>
              <p className="amount formateRation">
                {summaryData.ratio24H.toFixed(1)}%
              </p>
            </div>
            <div className="sec-2-box-wrap">
              <p className="title">Last 24H</p>
              <p className="subtitle">WINS PAID</p>
              <p className="amount formateRation">
                {formatAmountInMillionsThousandsBillions(
                  summaryData.distributedAmount24H
                )}
              </p>
            </div>
            <div className="sec-2-box-wrap">
              <p className="title">All time</p>
              <p className="subtitle">WINS PAID</p>
              <p className="amount formateRation">
                {formatAmountInMillionsThousandsBillions(
                  summaryData.distributedAmountAllTime
                )}
              </p>
            </div>
          </>
        )}
      </div>

      <BaseButton title="PLAY" onClick={connectToPlay} />
      <p
        style={{ color: "#FFD23E", fontSize: "1.5rem", cursor: "pointer" }}
        onClick={connectToPlay}
      >
        And earn crypto
      </p>
    </div>
  );
}

export default SectionTwo;

import React from "react";
import "./BaseButton.css";

const BaseButton = ({ title, onClick, color = "#FFD23E" }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: color }}
      className="btn-wrapper"
    >
      <div className="b-b-t">{title}</div>
    </button>
  );
};

export default BaseButton;

import React, { useState } from "react";
import "./FirstComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import Modal from "../../../components/Modal/Modal";
import { generateUUID } from "../../../utils";
const FirstComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryparam = window.location.search;
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/share${queryparam}`, "_self");
  };
  return (
    <div className="container">
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <div>
          <video
            controls
            autoPlay
            width={window.innerWidth - 300}
            height={window.innerHeight - 300}
            id="hero-video"
          >
            <source
              src="https://storage.googleapis.com/anima-react/update-mobile-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
      <div className="row">
        <div className="col-md-8">
          <p className="subtitle-1">Web3 multi level affiliate program</p>
          <h2 className="new-title">
            DO YOU HAVE<br></br> FRIENDS?
            <span className="span-text">YES ?</span>
          </h2>
          <p className="subtitle-2">Earn money now!</p>
          <p className="subtitle">
            If not find some new friends & <br></br> Come back to us
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <BaseButton title="Let's get STARTED" onClick={connectToPlay} />
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            alignItems: "right",
            marginRight: "0",
          }}
        >
          <div className="beside-img">
            <p className="running-aff">/ Running affiliate program</p>
            <img
              src="https://storage.googleapis.com/anima-react/rectangle.png"
              className="girle-img"
              width="400"
            />

            <img
              src="https://storage.googleapis.com/anima-react/smallgirle.png"
              width="400"
              className="girle-img-1"
            />
          </div>
          <button className="first-btn">
            <div onClick={toggleModal}>
              <img
                src="https://storage.googleapis.com/anima-react/Polygon.png"
                onClick={toggleModal}
              />
              Watch Affiliate Trailer
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;

import React from "react";
import { BsChevronDown } from "react-icons/bs";
import "./Feedback.css";

const Feedback = () => {
  const accordionItems = [
    {
      title: "How to get matic tokens?",
      body: "Buy tokens easily with credit/debit or bank transfer instantly ans easily via your cryptowallet. Or exchange other crypto to matic easily and safetly with our best swap service.",
    },
    {
      title: "How to sell my winning matic tokens?",
      body: "Buy tokens easily with credit/debit or bank transfer instantly ans easily via your cryptowallet. Or exchange other crypto to matic easily and safetly with our best swap service.",
    },
    {
      title: "How to send my winning matic tokens?",
      body: "Buy tokens easily with credit/debit or bank transfer instantly ans easily via your cryptowallet. Or exchange other crypto to matic easily and safetly with our best swap service.",
    },
  ];
  return (
    <div id="main">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12 text">
            <span>The official token of the game</span>
            <h2>POLYGON MATIC</h2>
          </div>
          <div className="col-md-6 col-sm-12 text">
            <div>
              <p>
                The top 10 crypto token, #1 web3 blockchain, trusted by
                Instagram and Meta
              </p>
            </div>
          </div>
          <div className="col-md-2 col-sm-12 img-with-drop-eight">
            <div style={{ padding: "0 8px" }}>
              <div className="back">
                <img
                  src="https://storage.googleapis.com/anima-react/infinte.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            {/* <ul className="navbar-nav ml-auto mr-5">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Matic
                </a>
                <div
                  className="dropdown-menu "
                  aria-labelledby="navbarDropdown"
                ></div>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="accordion" id="faq">
          {accordionItems.map((item, index) => (
            <div className="card" key={index}>
              <div
                className="card-header"
                id={`faqhead${index}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-header-link d-flex justify-content-between align-items-center"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#faq${index}`}
                  aria-expanded="true"
                  aria-controls={`faq${index}`}
                >
                  <span className="title">{item.title}</span>
                </button>
                <BsChevronDown
                  data-toggle="collapse"
                  data-target={`#faq${index}`}
                  aria-expanded="true"
                  aria-controls={`faq${index}`}
                  className="arrow"
                />
              </div>

              <div
                id={`faq${index}`}
                className="collapse "
                aria-labelledby={`faqhead${index}`}
                data-parent="#faq"
              >
                <div className="card-body">{item.body}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedback;

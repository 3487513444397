import React from "react";
import "./FirstComponent.css";
const FirstComponent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 first-section-header">
          <h2 className="how-to-plaly-title">
            REAL WEB3 <span>pvp</span> <br></br>
            TRADING GAME
          </h2>
          <p className="how-to-plaly-subtitle">Will bitcoin go up or down?</p>
        </div>
        <div className="col-md-6"></div>
        <div className="row">
          <div className="rotate-title">
            choose your<br></br> side wisely
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;

import React from "react";
import "./EightComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const EightComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/share${queryparam}`, "_self");
  };
  return (
    <div className="eight-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-md-1 order-2">
            <ul>AFFILIATE PROFITS </ul>
            <h2>Get your profits every day at 12:00 GMT</h2>
            <p>
              Profits earned with the affiliate program are seamlessly and
              automatically deposited into your crypto wallet. Be aware that all
              earnings from your affiliate link created on this wallet address
              will be transferred only to this wallet.
            </p>
            <div className="row">
              <BaseButton title="Let's GET STARTED " onClick={connectToPlay} />

              <button className="eight-btn" onClick={connectToPlay}>
                Easy and Free
              </button>
            </div>
          </div>
          <div className="col-md-6 order-md-2 order-1">
            <img
              src="https://storage.googleapis.com/anima-react/affilliate.png"
              className="side-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EightComponent;

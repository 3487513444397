import React, { useState, useEffect } from "react";
import "./FaqEightOne.css";
import { BsChevronDown } from "react-icons/bs";

function FaqEightOne() {
  const [faqData, setFaqData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.upvsdown.com/v1/config/faq?lang=en")
      .then((response) => response.json())
      .then((data) => {
        setFaqData(data.affiliate);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="sec-4-home">
      <div className="sec-8-table">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 className="sec-8-head">FAQ</h1>
          <p className="sec-4-head-right" style={{ cursor: "pointer" }}>
            Affiliate Program <BsChevronDown />
          </p>
        </div>
        <div className="sec-8-table-inner">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="accordion-faq" id="faq">
              {faqData.map((faqItem, index) => (
                <div className="card" key={index}>
                  <div
                    className="card-header"
                    id={`faqhead${index + faqItem.id}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn2 btn-header-link d-flex justify-content-between align-items-center"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#faq${index + faqItem.id}`}
                      aria-expanded="true"
                      aria-controls={`faq${index + faqItem.id}`}
                    >
                      <span className="title">{faqItem.question}</span>
                    </button>
                    <BsChevronDown
                      className="arrow"
                      data-toggle="collapse"
                      data-target={`#faq${index + faqItem.id}`}
                      aria-expanded="true"
                      aria-controls={`faq${index + faqItem.id}`}
                    />
                  </div>
                  <div
                    id={`faq${index + faqItem.id}`}
                    className={`collapse ${index === 0 ? "" : ""}`}
                    aria-labelledby={`faqhead${index + faqItem.id}`}
                    data-parent="#faq"
                  >
                    <div
                      className="card-body"
                      dangerouslySetInnerHTML={{ __html: faqItem.answer }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="footer-container">
          <label className="footer-text">&#9632; 1000011.1000011</label>
        </div>
      </div>
    </div>
  );
}

export default FaqEightOne;

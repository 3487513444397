import React from "react";
import "./SevenComponent.css";
import BaseButton from "../../../components/Button/BaseButton";
import { generateUUID } from "../../../utils";
const SevenComponent = () => {
  const queryparam = window.location.search;
  const connectToPlay = () => {
    window.open(`https://cryptoclash.gg/share${queryparam}`, "_self");
  };
  return (
    <div className="seven-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-md-1 order-2">
            <ul>WEEKLY JACKPOT </ul>
            <p className="create-aff-p">
              Jumbo ... When your friends win<br></br> the jackpot you also win!
            </p>
            <div className="row pt-4">
              <div className="col seven-earn-crypto">
                <BaseButton
                  title="Let's GET STARTED "
                  onClick={connectToPlay}
                />

                <button
                  className="btn"
                  onClick={connectToPlay}
                  style={{ cursor: "pointer" }}
                >
                  Easy and Free
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-md-2 order-1">
            <img
              src="https://storage.googleapis.com/anima-react/second-image-beside.png"
              width="500"
              className="first-image-beside"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SevenComponent;

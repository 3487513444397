import React from "react";
import "./FifthComponent.css";
import InvestmentBar from "./invesment/InvestmentBar";
const FifthComponent = () => {
  return (
    <div className="fifth-parnet">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <span>Next</span>
            <ul className="fifth-title">INVESTMENT BAR SELECT YOUR INVESTMENT</ul>
            <p>
              At the base of the Crypto Clash webpage, users have access to a
              sophisticated Investment Allocation feature. This tool empowers
              players to strategically select and allocate a specific quantity
              of Polygon (MATIC) coins for investment purposes.
            </p>
          </div>
          <div className="col-md-4">
            <InvestmentBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthComponent;

import React from "react";
import "./home.css";
import HomeHero from "./sections/Hero";
import SectionTwo from "./sections/SectionTwo";
import SectionThree from "./sections/SectionThree";
import SectionFour from "./sections/SectionFour";
import SectionFive from "./sections/SectionFive";
import SectionSix from "./sections/SectionSix";
import SectionSeven from "./sections/SectionSeven";
import Header from "../../components/header/Header";
import EightComponent from "../howtoplay/eightsection/EightComponent";
import NineComponent from "../howtoplay/nineSection/NineComponent";
import SectionEight from "./sections/SectionEight";
import SectionNine from "./sections/SectionNine";
import { FaChevronDown } from "react-icons/fa";
function Home() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <HomeHero />
      <SectionTwo />
      <SectionThree />
      <SectionFour
        rightItem={
          <div className="sec-4-right-item">
            <p>All Time</p> <FaChevronDown />
          </div>
        }
      />
      <EightComponent />
      <NineComponent />
      <SectionFive />
      <SectionSix />
      <SectionEight />
      <SectionNine />
    </div>
  );
}

export default Home;

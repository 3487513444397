import React from "react";
import "./SectionFive.css";
function SectionFive() {
  const leftSec = [
    "Best win ratio , 50% +",
    "No deposit , you control your funds.",
    "You play peer to peer not against the house.",
  ];
  const rightSec = [
    "Win & go , you get your winnings directly to your personal wallet.",
    "The smart contract that manages the game is fully audited and verified by best company in the indestry CERTIK !",
  ];
  return (
    <div className="sec-2-home">
      <div className="sec-5-contain-main">
        <p className="sec-5-txt-top">
          <span className="sec-5-hero-txt">5</span> KEY BENEFITS
        </p>
        <div className="sec-5-contain">
          <div className="sec-t-contain-child">
            {leftSec.map((txt) => {
              return <p> &#9632; {txt} </p>;
            })}
          </div>
          <div className="sec-t-contain-child">
            {rightSec.map((txt) => {
              return <p> &#9632; {txt} </p>;
            })}
          </div>
        </div>
        <div className="certik">

        <img
          className="sec-5-right-icon-hero"
          src="https://storage.googleapis.com/anima-react/certikLogoYellow.png"
        />
        </div>
      </div>
    </div>
  );
}

export default SectionFive;

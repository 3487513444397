import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import HowToPlay from "./Pages/howtoplay/HowToPlay";
import BecomeAffiliate from "./Pages/becomeaffiliate/BecomeAffiliate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/howToPlay" element={<HowToPlay />} />
        <Route path="/becomeAffilliate" element={<BecomeAffiliate/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
